<template>
  <div>
      <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <md-icon>list</md-icon>
            <h3 class="pl-2 text-uppercase">Automation</h3>
          </div>
          <div class="flex">
           <md-button class="outline-gray-400 rounded ">
             <md-icon>date_range</md-icon> 28 March, 2020
           </md-button>
           <md-icon>remove</md-icon>
           <md-button class="outline-gray-400 rounded ">
             <md-icon>date_range</md-icon> 28 March, 2020
           </md-button>
            <md-button
              class="bg-green ml-3 text-white rounded-md text-uppercase"
            >
              <md-icon class="text-white">add</md-icon> Create New
            </md-button>
          </div>
        </div>
      </template>
      <CardBody class="p-3">
        <!-- <div class="mb-3 flex justify-between flex-wrap justify-sm-center justify-xs-center">
          <SelectComponent placeholder="Select one" class="w-64 m-2" />
        </div>
        <md-divider></md-divider> -->
        <Table :items="automation" @onSelect="onSelect" hover-action>
          <template slot="row" slot-scope="{ item }" md-selectable="single">
            <md-table-cell md-sort-by="id" md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
            <md-table-cell md-sort-by="campaignName" md-label="CAMPAIGN NAME">{{ item.campaignName }}</md-table-cell>
            <md-table-cell md-sort-by="phase1" md-label="PHASE 1" class="text-green">
             <md-icon class="m-0 text-green">check</md-icon> {{ item.phase1 }}
            </md-table-cell>
            <md-table-cell md-sort-by="phase2" md-label="PHASE 2">
              {{ item.phase2 }}
              <span class="opacity-75"> ( pending ) </span>
            </md-table-cell>
            <md-table-cell md-sort-by="phase3" md-label="PHASE 3">
              {{ item.phase3 }}
              <span class="opacity-75"> ( pending ) </span>
            </md-table-cell>
            <md-table-cell md-sort-by="status" md-label="STATUS" class="text-green">{{ item.status }}</md-table-cell>
            <span class="action">
              <md-icon class="bg-victoria rounded-full p-1 text-white text-base mr-1">visibility</md-icon>
              <md-icon class="bg-victoria rounded-full p-1 text-white text-base mr-1">edit</md-icon>
              <md-icon class="bg-victoria rounded-full p-1 text-white text-base mr-1">delete</md-icon>
            </span>
          </template>
        </Table>
      </CardBody>
    </Card>
    <Dialog>
      <CreateNewNotice />
    </Dialog>
  </div>
</template>

<script>
import {
    Card,
    Table,
    Dialog,
    CardBody
    } from "@/components";
import CreateNewNotice from "@/components/molecule/communication/CreateNewNotice";
import { automation } from "@/data/communication/interaction";
import { mapMutations } from "vuex";

export default {
    components: {
        Card,
        Table,
        CardBody,
        Dialog,
        CreateNewNotice
  },
  data() {
      return {
          automation: automation
      }
  },
   methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    onSelect() {

    }
  },
}
</script>